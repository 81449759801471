import { rootReducer } from 'store/reducers/index'
import { GotuuriState } from 'models/gotuuri-state'
import {
  useGetExperiences,
  useGetExperienceDetail,
  useHandleExperienceSchedules,
  usePostUpdateProfile,
  usePostAvailableHour,
  useGetHighlights,
  useGetCities,
  useGetCountries,
  useGetCategories,
  useHandlePurchases,
  useHandleUserSession,
  useHandleBooking,
  useCouponValidation,
  useHandleGiftCard,
  useHandleFavorites,
  useHandleFilterData,
  useGetExperiencesProperties,
  useGetCarousels,
  useHandleAuthorization,
  useHandleTurnstile,
} from './base'

import { useReducer } from 'react'

export const useGotuuriStore = (
  isLoggedIn?: boolean,
  userData?: any,
  bookingData?: any,
  userLocale?: any,
  giftCardData?: any
) => {
  const initialState: GotuuriState = {
    experiences: null,
    experiencesProperties: null,
    loadingExperiences: true,
    pagination: null,
    loadingExperienceDetail: true,
    notFoundExperience: false,
    experience: null,
    companySchedule: null,
    availableHours: null,
    cities: null,
    citiesByCountry: null,
    country: null,
    countries: null,
    cityExperiences: null,
    highlights: null,
    highlightExperiences: null,
    categories: null,
    categoryExperiences: null,
    isLoggedIn,
    loadingPayment: false,
    user: userData || null,
    purchases: null,
    bookingData: bookingData || null,
    uniqueDates: null,
    hasSearchResults: false,
    referentCode: null,
    locale: userLocale || null,
    giftCardData: giftCardData || null,
    loadingRedeem: false,
    favoriteExperiences: null,
    loadingProfileUpdate: false,
    filterData: null,
    carousels: null,
    disableSchedules: null,
    stepUpRedirected: false,
    authData: null,
    isNewUser: undefined,
    isProccessingPayment: false,
  }

  const [state, dispatch] = useReducer(rootReducer, initialState)

  const hooks = {
    ...useGetExperiences(dispatch, state),
    ...useGetExperienceDetail(dispatch, state),
    ...useHandleExperienceSchedules(dispatch, state),
    ...usePostAvailableHour(dispatch, state),
    ...usePostUpdateProfile(dispatch, state),
    ...useGetCities(dispatch, state),
    ...useGetCountries(dispatch, state),
    ...useGetHighlights(dispatch, state),
    ...useGetCategories(dispatch, state),
    ...useHandleUserSession(dispatch),
    ...useHandlePurchases(dispatch, state),
    ...useHandleBooking(dispatch),
    ...useCouponValidation(dispatch, state),
    ...useHandleGiftCard(dispatch),
    ...useHandleFavorites(dispatch, state),
    ...useHandleFilterData(dispatch),
    ...useGetExperiencesProperties(dispatch, state),
    ...useGetCarousels(dispatch),
    ...useHandleAuthorization(dispatch, state),
    ...useHandleTurnstile(dispatch, state),
  }
  return {
    state,
    dispatch,
    hooks,
  }
}
