import { useEffect } from 'react'
import logo from '@/assets/svgs/logo-blue.svg'
import { useTranslation } from 'react-i18next'

const CyberSource3DSRedirect = () => {
  const { t } = useTranslation()

  const isWeb = typeof window !== 'undefined' && !!window.localStorage.getItem('localBookingData')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.localStorage.getItem('localBookingData')) {
        window.localStorage.setItem('stepUpRedirected', 'true')
      }
    }
  }, [])

  const handleClick = () => {
    window.top.location.href =
      import.meta.env.VITE_3DS_ENV === 'production'
        ? 'https://gotuuri.com'
        : 'https://staging.gotuuri.com'
  }

  return (
    <div className="flex justify-between mb-4 flex-col items-center md:hidden">
      <img
        className="mt-12 mb-12"
        src={logo}
        width="160"
        height={'34.84'}
        alt="Logo"
      />

      <h1 className="mb-8">{t('redirect3ds.allRight')}</h1>
      {!isWeb && (
        <>
          <p className="mb-8 text-center">
            {t('redirect3ds.allRightDescription')}
          </p>
          <button
            onClick={handleClick}
            className="bg-black text-white font-bold py-2 px-4 rounded-3xl pr-10 pl-10"
          >
            {t('common.continue')}
          </button>
        </>
      )}

      {isWeb && (
        <>
        <p className='mb-8'>
          {t('redirect3ds.pleaseWait')}</p>
        </>
      )}
    </div>
  )
}

export default CyberSource3DSRedirect
