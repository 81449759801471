import { useEffect } from 'react'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useListenLocalStorage } from '@/hooks/useListenLocalStorage'
import { setBookingDataAction } from '@/store/actions'
import { differenceInMinutes } from 'date-fns'

export function CheckBookingDataValidity(date) {
  if (!date) return false
  const now = new Date()
  const minutesDifference = differenceInMinutes(now, new Date(date))
  return minutesDifference > 60
}

const getLocalBookingData = () => {
  const { localBookingData } = useListenLocalStorage(['localBookingData'])
  const { dispatch, bookingData } = useGotuuriContext()
  useEffect(() => {
    if (!!localBookingData) {
      const bookingData = JSON.parse(String(localBookingData))
      if (CheckBookingDataValidity(bookingData?.createdAt)) {
        dispatch(setBookingDataAction(null))
        return
      }
      if (bookingData?.experience?.id) {
        dispatch(setBookingDataAction(bookingData))
      }
    }
  }, [localBookingData])
}

export default getLocalBookingData
