import { CalendarIcon } from '@/assets/icons'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { googleTrack, track } from '@/utils/analytics'
import { CalendarDate, getLocalTimeZone } from '@internationalized/date'
import { Button, Calendar, Divider } from '@nextui-org/react'
import { Accordion } from 'components/Accordion'
import { addDays } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useFormatDate } from 'utils/formatDate.js'
import './calendar.css'

interface CalendarRowProps {
  handleBookingDate: (data) => void
  isDateRange?: boolean
  rangeMinDate?: string
  rangeMaxDate?: string
}

const CalendarRow = ({
  handleBookingDate,
  isDateRange = false,
  rangeMinDate = null,
  rangeMaxDate = null,
}: CalendarRowProps) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [bookingDate, setBookingDate] = useState(null)
  const {
    hooks: { postAvailableHour },
    state: { companySchedule, experience, bookingData, disableSchedules },
  } = useGotuuriContext()

  const setGlobalBookingDate = (date) => {
    googleTrack('ExperienceDateSelect')
    handleBookingDate(date)
  }

  const handleSelectDate = (item) => {
    const body = {
      company:
        experience?.companies[0].id ?? bookingData?.experience?.companies[0].id,
      experience: experience?.id ?? bookingData?.experience?.id,
      date_booking: useFormatDate(item, 'yyyy-MM-dd'),
    }
    setBookingDate(new Date(item))
    postAvailableHour(body)
    setGlobalBookingDate(item)
  }

  useEffect(() => {
    if (
      !!bookingData &&
      bookingData?.experience?.id === id &&
      !!disableSchedules
    ) {
      handleSelectDate(bookingData?.bookingDate ?? null)
      setGlobalBookingDate(bookingData?.bookingDate ?? null)
    }
  }, [bookingData, disableSchedules])

  const minDate =
    new Date() > new Date(rangeMinDate) ? new Date() : new Date(rangeMinDate)
  const minAddDate = addDays(
    new Date(minDate.toDateString()),
    companySchedule?.anticipationDay || 0
  )
  const maxDate =
    new Date() > new Date(rangeMaxDate) ? new Date() : new Date(rangeMaxDate)
  const getDisabledDates = useCallback(
    () =>
      disableSchedules?.disabledDates?.map((date) =>
        useFormatDate(date, 'yyyy-MM-dd')
      ),
    [disableSchedules]
  )

  const disabledDates = useCallback(
    (date: Date) => {
      if (!!!disableSchedules?.disabledDates) return
      const formattedDate = useFormatDate(date, 'yyyy-MM-dd')
      return getDisabledDates()?.includes(formattedDate)
    },
    [getDisabledDates]
  )

  const hasAvailableSchedule = (date?: Date) => {
    if (!companySchedule) return true
    const dayOfWeek = date?.getDay()

    const daySchedules = {
      0: companySchedule?.sunday,
      1: companySchedule?.monday,
      2: companySchedule?.tuesday,
      3: companySchedule?.wednesday,
      4: companySchedule?.thursday,
      5: companySchedule?.friday,
      6: companySchedule?.saturday,
    }

    const specificDaySchedule =
      daySchedules[dayOfWeek as keyof typeof daySchedules] || []
    return specificDaySchedule.length > 0
  }

  const parsedValue = (value: Date) => {
    if (!value) return null

    const date = new Date(value)
    const dateValue = new CalendarDate(
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
      date.getUTCDate()
    )
    return dateValue
  }
  const calendarProps = isDateRange
    ? {
        minValue: parsedValue(minAddDate),
        maxValue: parsedValue(maxDate),
      }
    : {
        minValue: parsedValue(
          addDays(new Date(), companySchedule?.anticipationDay || 0)
        ),
      }

  return (
    <>
      <Accordion
        isOpen={true}
        header={(isOpen) => (
          <div
            className="flex flex-row w-full select-none"
            role="button"
            onClick={() => track('Experience Calendar View')}
          >
            <CalendarIcon className="h-5 w-5 mr-4 my-auto" />
            <h3 className="text-base text-black text-center truncate">
              {bookingDate
                ? useFormatDate(bookingDate, 'dd/MM/yyyy')
                : t('bookingCard.selectDate')}
            </h3>
            <ArrowBlackImg
              className={`ml-auto ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        )}
      >
        {(close) =>
          isDateRange &&
          (String(minDate) == String(maxDate) || minAddDate > maxDate) ? (
            <div className="max-h-48 mt-2 overflow-y-auto p-px scrollbar-hide overscroll-none">
              <Button
                isDisabled
                className="shadow rounded-2xl cursor-pointer h-12 text p-3 mb-2 w-full justify-start"
              >
                {t('uniqueDatesRow.noSchedules')}
              </Button>
            </div>
          ) : (
            <div className="m-1">
              <Calendar
                className="mt-2"
                hideDisabledDates
                disableAnimation
                weekdayStyle="short"
                {...calendarProps}
                onChange={(item) => {
                  const date = item.toDate(getLocalTimeZone())
                  track('Experience Date Select')
                  googleTrack('ExperienceDateSelect')
                  setBookingDate(date)
                  handleSelectDate(date)
                  close()
                }}
                isDateUnavailable={(dateValue) => {
                  const date = dateValue.toDate(getLocalTimeZone())

                  const result = disabledDates(date)
                  if (result) return true
                  if (!!companySchedule?.all_days?.length) {
                    return false
                  }
                  return !hasAvailableSchedule(date)
                }}
                value={parsedValue(bookingDate) as any}
                calendarWidth="100%"
                classNames={{
                  cell: 'w-full md:h-auto h-3/4',
                  cellButton: 'w-full md:h-auto h-3/4',
                  gridHeaderCell: 'w-full md:h-auto h-3/4 !font-bold !bg-white',
                  gridHeader: 'shadow-none !bg-white',
                  gridHeaderRow: '!px-0 text-primary !bg-white',
                  headerWrapper: 'px-0',
                  gridWrapper: 'pb-0',
                  header: 'capitalize-first !bg-white',
                  title: 'text-base text-gray-700 !bg-white',
                  base: 'shadow-none rounded-xl !bg-white',
                  nextButton: 'text-gray-700',
                  prevButton: 'text-gray-700',
                }}
              />
            </div>
          )
        }
      </Accordion>
      <Divider className="mb-3" />
    </>
  )
}
export default CalendarRow
