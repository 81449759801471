import React from 'react'
import {
  FACEBOOK,
  INSTAGRAM,
  YOUTUBE,
  TIKTOK,
  LINKEDIN,
  GOTUURI_BLOG,
} from '@/constants/socialLinks'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { track } from '@/utils/analytics'
import { Path } from '@/constants/path'

import {
  DowloadOnAppStoreIcon,
  FacebookIcon,
  GooglePlayBadgeIcon,
  GotuuriLogoIcon,
  InstagramIcon,
  LinkedinIcon,
  MasterCardIcon,
  PayPal2014Icon,
  TiktokIcon,
  VisaColorIcon,
  YoutubeIcon,
} from '@/assets/icons'
import { APPSTORE, GOOGLEPLAY } from '@/constants/contact'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div className="bg-black h-auto w-full bottom-0 layout-padding py-28 mt-auto">
      <div className="grid grid-cols-2 md:flex md:flex-row md:justify-between md:grid-cols-4 text-white space-y-12 md:space-y-0">
        <div className="mt-8 col-span-2 md:col-span-1">
          <Link to="/" aria-label="Gotuuri main web">
            <GotuuriLogoIcon className="w-40 mb-8 text-white" />
          </Link>
          <div className="flex flex-row">
            <a
              className="gotuuri-filter"
              href={FACEBOOK}
              aria-label="Gotuuri Facebook"
              target="_blank"
            >
              <FacebookIcon className="h-8 pl-0 p-2 md:mb-6" />
            </a>
            <a
              className="gotuuri-filter"
              href={INSTAGRAM}
              aria-label="Gotuuri Instagram"
              target="_blank"
            >
              <InstagramIcon className="h-8 w-8 p-2" />
            </a>
            <a
              className="gotuuri-filter"
              href={YOUTUBE}
              aria-label="Gotuuri Youtube"
              target="_blank"
            >
              <YoutubeIcon className="h-8 w-8 p-2" />
            </a>
            <a
              className="gotuuri-filter"
              href={TIKTOK}
              aria-label="Gotuuri Tiktok"
              target="_blank"
            >
              <TiktokIcon className="h-8 w-8 p-2" />
            </a>
            <a
              className="gotuuri-filter"
              href={LINKEDIN}
              aria-label="Gotuuri Linkedin"
              target="_blank"
            >
              <LinkedinIcon className="h-8 w-8 p-2" />
            </a>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <p className="mb-2 font-semibold">{t('footer.gotuuri')}</p>
          <Link to="/about">
            <p className="hover:text-primary">{t('footer.AboutUs')}</p>
          </Link>
          <Link
            to={Path.GIFTCARDS}
            onClick={() => {
              track('Buy Gift Card Click', { screen: 'Footer' })
            }}
          >
            <p className="hover:text-primary">{t('footer.giveAnExperience')}</p>
          </Link>
          <a
            href={GOTUURI_BLOG}
            target="_blank"
            onClick={() => {
              track('Blog click')
            }}
          >
            <p className="hover:text-primary">{t('footer.blog')}</p>
          </a>
          <Link to={Path.TUURI_REGISTER}>
            <p className="mb-2 hover:text-primary">
              {t('footer.tuuriRegister')}
            </p>
          </Link>
        </div>

        <div className="flex flex-col gap-2">
          <p className="mb-2 ">{t('footer.support')}</p>
          <Link to="/terms-and-conditions">
            <p className="hover:text-primary">
              {t('footer.termsAndConditions')}
            </p>
          </Link>
          <Link to="/privacy-policies">
            <p className="hover:text-primary">{t('footer.privacyPolicies')}</p>
          </Link>
          <Link to="/faqs">
            <p className="hover:text-primary">{t('footer.faqs')}</p>
          </Link>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-1 gap-2 col-span-2 md:col-span-1">
          <div>
            <p className="mb-4">{t('footer.paymentMethods')}</p>
            <div className="flex gap-2">
              <VisaColorIcon className="w-10 h-7 p-2 bg-white rounded-md" />
              <MasterCardIcon className="w-10 h-7 p-1.5 bg-white rounded-md" />
              <PayPal2014Icon className="w-10 h-7 p-1 bg-white rounded-md" />
            </div>
          </div>
          <div>
            <p className="mb-4">{t('footer.downloadApp')}</p>
            <div className="flex flex-col gap-2">
              <a target="_blank" aria-label="App Store link" href={APPSTORE}>
                <DowloadOnAppStoreIcon className="w-32 h-12" />
              </a>
              {/* <a target="_blank" href={GOOGLEPLAY}>
                <GooglePlayBadgeIcon className="w-32" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
