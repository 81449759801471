import { useState } from 'react'
import countryRegionData from 'country-region-data/dist/data-umd'
import { Select, SelectItem } from '@nextui-org/select'
import { PANAMA_CODE } from '@/constants/common'
import { useTranslation } from 'react-i18next'
interface CountryRegionSelectItemProps {
  clearErrors: any
  setValue: any
  register: any
  defaultCountry?: string
  defaultCity?: string
  height?: string
  labelCountry?: string
  labelCity?: string
  errors?: any
  mode?: any
}
const CountryRegionSelect = ({
  clearErrors,
  setValue,
  register,
  defaultCountry = PANAMA_CODE,
  defaultCity,
  height,
  labelCountry = '',
  labelCity = '',
  errors = undefined,
  mode = 1,
}: CountryRegionSelectItemProps) => {
  const [countryRegions, setCountryRegions] = useState(
    countryRegionData.find((data) => data?.countryShortCode === defaultCountry)
      ?.regions
  )
  const { t } = useTranslation()

  return (
    <div
      className={`grid grid-cols-2 gap-4 ${mode === 2 && '!gap-6'} justify-between flex-shrink-0 -mt-2 ${!!errors?.country || (!!errors?.city && 'mb-2')}`}
    >
      <div className=" ">
        {!!labelCountry &&
          (typeof labelCountry == 'string' ? (
            <p
              className={`text-sm mb-1 ${!!errors?.country && 'text-[#f31260]'}`}
            >
              {labelCountry} <span className="text-red-500">*</span>
            </p>
          ) : typeof labelCountry != 'undefined' ? (
            labelCountry
          ) : null)}
        <select
          {...register('country', { required: t('payment.required') })}
          className={`custom-select px-3 text-sm py-2.5 border-2 ${height}
            ${mode === 2 && ' !rounded-2xl !bg-mediumGray !border-0'} 
            ${errors?.country ? 'border-[#f31260]' : 'border-gray-200'} 
            rounded-xl bg-white  focus:outline-none focus:ring-0 focus:ring-black focus:border-black`}
          defaultValue={defaultCountry}
          onChange={(selectedCountry) => {
            const selectedCountryData = countryRegionData.find(
              (data) => data?.countryShortCode === selectedCountry.target.value
            )
            setValue('country', selectedCountry.target.value)
            setValue('city', null)
            setCountryRegions(selectedCountryData?.regions || [])
          }}
        >
          <option value="" disabled>
            {labelCountry}
          </option>
          {countryRegionData.map((country: any) => (
            <option
              key={country?.countryShortCode}
              value={country?.countryShortCode}
            >
              {country?.countryName}
            </option>
          ))}
        </select>
        {!!errors?.country?.message?.toString() && (
          <p className=" text-[#f31260] mt-1 text-xs">
            {errors?.country?.message?.toString()}
          </p>
        )}
      </div>

      <div className=" ">
        {!!labelCity &&
          (typeof labelCity == 'string' ? (
            <p className={`text-sm mb-1 ${!!errors?.city && 'text-[#f31260]'}`}>
              {labelCity} <span className="text-red-500">*</span>
            </p>
          ) : typeof labelCity != 'undefined' ? (
            labelCity
          ) : null)}
        <select
          {...register('city', { required: t('payment.required') })}
          defaultValue={defaultCity}
          onChange={(selectedRegion) => {
            setValue('city', selectedRegion?.target.value)
            clearErrors('city')
          }}
          className={`custom-select px-3 text-sm py-2.5 border-2 ${height}
            ${mode === 2 && ' !rounded-2xl !bg-mediumGray !border-0'} 
            ${errors?.country ? 'border-[#f31260]' : 'border-gray-200'} 
            rounded-xl bg-white  focus:outline-none focus:ring-0 focus:ring-black focus:border-black`}
        >
          <option value="" disabled>
            {labelCity || t('payment.city')}
          </option>
          {countryRegions.map((region: any) => (
            <option key={region?.name} value={region?.shortCode}>
              {region?.name}
            </option>
          ))}
        </select>
        {!!errors?.city?.message?.toString() && (
          <p className=" text-[#f31260] mt-1 text-xs">
            {errors?.city?.message?.toString()}
          </p>
        )}
      </div>
    </div>
  )
}

export default CountryRegionSelect
