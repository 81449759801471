import { GotuuriState } from '@/models/gotuuri-state'
import { post } from '@/services/axios/axios'
import {
  setAuthDataAction,
  setBookingDataAction,
  setGiftCardDataAction,
} from '@/store/actions'
import { ActionType } from '@/store/actions/base-action'
import { authBodyUtil } from '@/utils/authBodyUtil'

export const useHandleAuthorization = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const { setAuthorizationBody, setValidateBody } = authBodyUtil()

  const setupAuth = async (card, callback = () => {}) => {
    callback()
    await post({ path: 'auth-setup', data: { card } })
      .then((data: any) => {
        dispatch(setAuthDataAction(data.data))
        dispatch(
          setBookingDataAction({
            ...state.bookingData,
            transactionId: null,
            validateAuth: null,
            consumerAuthenticationInformation: null,
            validatedConsumerAuthenticationInformation: null,
            canSubmitPayment: null,
          })
        )
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
      })
  }

  const checkEnrollment = async (
    purchaseData,
    referenceId,
    deviceInformation,
    cardType
  ) => {
    const body = setAuthorizationBody(
      purchaseData,
      state,
      referenceId,
      deviceInformation,
      cardType
    )

    await post({
      path: 'auth-check-enrollment',
      data: body,
    })
      .then((data: any) => {
        dispatch(setAuthDataAction(data))
        if (!!state.bookingData) {
          dispatch(
            setBookingDataAction({
              ...state.bookingData,
              transactionId: data?.data?.transactionId,
              validateAuth: data?.data?.needStepUp,
              consumerAuthenticationInformation:
                data?.data?.consumerAuthenticationInformation,
              validateConsumerAuthenticationInformation: null,
              canSubmitPayment: null,
            })
          )
        }
        if (!!state.giftCardData) {
          dispatch(
            setGiftCardDataAction({
              ...state.giftCardData,
              transactionId: data?.data?.transactionId,
              validateAuth: data?.data?.needStepUp,
              consumerAuthenticationInformation:
                data?.data?.consumerAuthenticationInformation,
              validatedConsumerAuthenticationInformation: null,
              canSubmitPayment: null,
            })
          )
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error('There was a problem with the fetch operation:', error)
      })
  }

  const validateAuthorization = async (purchaseData) => {
    const body = setValidateBody(purchaseData, state)

    const validateResponse = await post({
      path: 'validate-authorization',
      data: body,
    })
      .then((data: any) => {
        if (!!state.bookingData) {
          dispatch(
            setBookingDataAction({
              ...state.bookingData,
              validatedConsumerAuthenticationInformation:
                data?.data?.consumerAuthenticationInformation,
              canSubmitPayment: data?.data?.canSubmitPayment,
            })
          )
          dispatch(setAuthDataAction(null))
        }
        if (!!state.giftCardData) {
          dispatch(
            setGiftCardDataAction({
              ...state.giftCardData,
              validatedConsumerAuthenticationInformation:
                data?.data?.consumerAuthenticationInformation,
              canSubmitPayment: data?.data?.canSubmitPayment,
            })
          )
        }
        return true
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error)
        return false
      })

    return validateResponse
  }

  return { setupAuth, checkEnrollment, validateAuthorization }
}
