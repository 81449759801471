import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { App } from "./App";

const rootElement = document.getElementById("root");
const isServerRendered = rootElement.getAttribute("data-server-rendered");

const VITE_PASSWORD = import.meta.env.VITE_PASSWORD

const VITE_ENVIROMENT = import.meta.env.VITE_NODE_ENV

const getCookie = (name: string) => {
  const value = `; ${document?.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts?.length === 2) return parts?.pop()?.split(';')?.shift();
}

const setCookie = (name: string, value: string, days: number) => {
  const d = new Date;
  d?.setTime(d?.getTime() + 24*60*60*1000*days);
  document.cookie = `${name}=${value}; expires=${d.toUTCString()}; path=/`;
}

const cookieName = 'allowed'
const cookieValue = 'true'

const askForCredentials = () => {
  if (getCookie(cookieName) === cookieValue) return

  let username = window.prompt('Usuario:')
  let password = window.prompt('Contraseña:')

  if (username !== 'admin' || password !== VITE_PASSWORD) {
    alert('Acceso denegado. Inténtelo de nuevo.')
    askForCredentials()
  } else {
    setCookie(cookieName, cookieValue, 1)
  }
}

if (VITE_ENVIROMENT != 'production') askForCredentials()

const renderApp = () => (
  <>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </>
)

if (isServerRendered) {
  ReactDOM.hydrateRoot(rootElement, renderApp());
} else {
  ReactDOM.createRoot(rootElement).render(renderApp());
}
