import { GotuuriState } from '@/models/gotuuri-state'
import { post } from '@/services/axios/axios'
import { ActionType } from '@/store/actions/base-action'
import { parseISO, format } from 'date-fns'
import { setPostAvailableHour } from 'store/actions'

export const usePostAvailableHour = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const postAvailableHour = async (body) => {
    const response = await post({
      path: 'get-available-schedule',
      data: body,
    }).then((data: any) => {
      let hours = data
      let bodyDate = parseISO(body?.date_booking)
      let formattedBodyDate = format(bodyDate, 'yyyy-MM-dd')

      if (state?.disableSchedules?.disableHours) {
        const formattedDisableHours = Object.keys(state.disableSchedules.disableHours).reduce((acc, key) => {
          const formattedKey = format(key, 'yyyy-MM-dd')
          acc[formattedKey] = state.disableSchedules.disableHours[key]
          return acc
        }, {})

        if (formattedDisableHours[formattedBodyDate]) {
          hours = hours.filter(hour => !formattedDisableHours[formattedBodyDate].includes(hour.hour))
        }
      }
      dispatch(setPostAvailableHour(hours))
    })
  }

  return { postAvailableHour }
}
