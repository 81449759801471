export const namePatternRegex =
  /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚàèìòùÀÈÌÒÙäëïöüÄËÏÖÜâêîôûÂÊÎÔÛ\s']{3,}$/
export const emailPatternRegex = /[a-zA-Z0-9ñÑ]+@[a-zA-ZñÑ]+\.[a-zA-ZñÑ]{2,3}/g
export const amoutPatternRegex = /^((200)|(1[0-9]{2})|([1-9][0-9]{1})|([5-9]))$/
export const dniPatternRegex = /^[^<>\[\]%&\$;(){}=:+*~#\\|'"`]+$/i
export const passwordPatternRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[^<>\"'&;]{8,}$/
//export const validateAmount = (amount: string | undefined) => amount.match(/^((200)|(1[0-9]{2})|([2-9][0-9]{1}))$/);
export const addressPatternRegex =
  /^(?![\W_]+$)[a-zA-Z0-9ÁÉÍÓÚÑáéíóúñ'"\s,.-]+$/
