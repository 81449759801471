import { GotuuriState } from '@/models/gotuuri-state'
import { post } from '@/services/axios/axios'
import { ActionType } from '@/store/actions/base-action'

export const useHandleTurnstile = (
  dispatch: (action: ActionType) => void,
  state: GotuuriState
): any => {
  const siteverify = async (token) => {
    const body = { token }

    const response = await post({
      path: 'turnstile-siteverify',
      data: body,
    })
      .then((data: any) => {
        return data
      })
      .catch((error) => {
        // Handle errors here
        console.error('There was a problem with the fetch operation:', error)
      })
      return response;
  }

  return { siteverify }
}
