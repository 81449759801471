import React, { useEffect, useState } from 'react'

import { Accordion } from 'components/Accordion'
import { Button, Divider } from '@nextui-org/react'
import { ArrowBlackImg } from '@/components/ArrowBlackImg'
import { useTranslation } from 'react-i18next'
import { useFormatDate } from '@/utils/formatDate'
import { useGotuuriContext } from '@/hooks/useGotuuriContext'
import { useParams } from 'react-router-dom'
import { googleTrack, track } from '@/utils/analytics'
import { CalendarIcon } from '@/assets/icons'

const buttonStyle =
  'shadow rounded-2xl cursor-pointer h-12 text p-3 mb-2 hover:text-primary w-full justify-start bg-white'

const UniqueDatesRow = ({ uniqueDates, handleBookingDate }) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [bookingDate, setBookingDate] = useState(null)

  const {
    hooks: { postAvailableHour },
    state: { experience, bookingData, disableSchedules },
  } = useGotuuriContext()

  const handleSelectDate = (item) => {
    const body = {
      company:
        experience?.companies[0].id ?? bookingData?.experience?.companies[0].id,
      experience: experience?.id ?? bookingData?.experience?.id,
      date_booking: useFormatDate(item, 'yyyy-MM-dd'),
    }
    setBookingDate(useFormatDate(item, t('uniqueDatesRow.dateFormat')))
    postAvailableHour(body)
    googleTrack('ExperienceDateSelect')
    handleBookingDate(item)
  }

  useEffect(() => {
    if (
      !!bookingData &&
      bookingData?.experience?.id === id &&
      disableSchedules
    ) {
      setBookingDate(
        useFormatDate(bookingData?.bookingDate, t('uniqueDatesRow.dateFormat'))
      )
      handleSelectDate(bookingData?.bookingDate)
    }
  }, [bookingData, disableSchedules])

  const ShowDates = () => {
    if (disableSchedules?.disabledDates) {
      const formattedDisabledDates = disableSchedules.disabledDates.map(
        (date) => useFormatDate(date, 'yyyy-MM-dd')
      )
      const formattedUniqueDates = uniqueDates.dates.map((date) =>
        useFormatDate(date, 'yyyy-MM-dd')
      )
      const _showDates = formattedUniqueDates.filter(
        (date) => !formattedDisabledDates.includes(date)
      )

      return _showDates
    } else {
      const updatedShowDates = uniqueDates.dates
      return updatedShowDates
    }
  }

  return (
    <>
      <Accordion
        header={(isOpen) => (
          <div
            className="flex flex-row w-full select-none"
            role="button"
            onClick={() => track('Experience Calendar View')}
          >
            <CalendarIcon className="h-5 w-5 mr-4 my-auto" />
            <h3 className="text-base text-black text-center truncate">
              {bookingDate ?? t('bookingCard.selectDate')}
            </h3>
            <ArrowBlackImg
              className={`ml-auto ${isOpen ? 'rotate-180' : ''}`}
            />
          </div>
        )}
      >
        {(close) => (
          <div className="max-h-48 mt-2 overflow-y-auto p-px scrollbar-hide overscroll-none">
            {!!ShowDates() ? (
              ShowDates()?.map((date, index) => (
                <Button
                  onClick={() => {
                    track('Experience Date Select')
                    googleTrack('ExperienceDateSelect')
                    handleSelectDate(date)
                    close()
                  }}
                  className={buttonStyle}
                  key={index}
                >
                  {useFormatDate(date, t('uniqueDatesRow.dateFormat'))}
                </Button>
              ))
            ) : (
              <Button className={buttonStyle}>
                {t('uniqueDatesRow.noSchedules')}
              </Button>
            )}
          </div>
        )}
      </Accordion>
      <Divider className="mb-4" />
    </>
  )
}

export default UniqueDatesRow
